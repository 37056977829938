.loader {
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid $blue;
  border-right: 10px solid $green;
  border-bottom: 10px solid $dark-blue;
  border-left: 10px solid $dark-green;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

$fontWeights: (
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800
);

@each $weight in $fontWeights {
  .fw-#{$weight} {
    font-weight: $weight
  }
}

@each $name, $color in $theme-colors {
  .control-#{$name} {
    @extend .text-dark;
    @extend .border-#{$name} !optional;
    @extend .shadow-none;
    background-color: scale-color($color, $lightness: 50%) !important;
    transition: all .2 ease-in-out;
    box-shadow: none !important;
    border-radius: 5px 5px 0px 0px !important;

    &:focus-visible,
    &:hover {
      background-color: lighten($color, 10%) !important;
      border-bottom: 1px solid scale-color($color, $saturation: 70%);
      transition: all .2 ease-in-out;
    }
  }
}

.card {
  border-radius: 10px !important;
}

.h-500 {
  max-height: 500px;
}

.dropdown-toggle::after {
  display: none;
}

.form-label {
  color: $dark !important;
  font-weight: 400;
}

.form-control {
  color: $dark !important;
  border: 2px solid rgba(0, 0, 0, 0.125) !important;
  box-shadow: none !important;
  transition: all .2s;
  border-radius: 5px !important;
  padding: 10px;

  &:focus-visible,
  &:hover {
    background-color: lighten($primary, 40%) !important;
    border: 2px solid $primary !important;
    border-radius: 5px !important;
    transition: all .2s;
  }
}

.form-check-input {
  margin-top: 3px;
  border: $dark solid;
}

.btn {
  font-size: 16px;
}

.btn-light {
  border: 2px solid rgba(0, 0, 0, 0.125);
  background-color: transparent;

  &:hover {
    background-color: $light;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: $light;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }
}

.nav-link {
  font-size: 16px;
  color: $dark;
  font-weight: 500;
  border: none !important;
  padding: 0px;
  margin-right: 24px;

  &.active {
    background-color: transparent !important;
    color: $dark-blue !important;
    border: none;
    border-bottom: 2px solid $dark-blue !important;
    font-weight: 500;
  }
}

.hover:hover {
  background-color: $light-grey;
}

.api-docs section {
  padding: map-get($map: $spacers, $key: 48) 0
}