$primary: #347AE4;
$primary-light: #61b0fe77;
$secondary: #0C7069;
$info: #e3b826;
$success: #00C58E;
$warning: #FFC700;
$danger: #FF7373;
$blue: #347AE4;
$dark-blue: #0565AA;
$navy: #1A5A83;
$green: #009E94;
$dark-green: #0C7069;
$light: #FAFAFB;
$grayscale: #ECEFEF;
$light-grey: #C2C2C2;
$grey: #929292;
$dark: #2B2C2D;

$enable-responsive-font-sizes: true;
$theme-colors: (
  "primary": $primary,
  "primary-light": $primary-light,
  "secondary": $secondary,
  "info": $info,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
  "blue": $blue,
  "dark-blue": $dark-blue,
  "navy": $navy,
  "green": $green,
  "dark-green": $dark-green,
  "light": $light,
  "grayscale": $grayscale,
  "grey": $grey,
  "light-grey": $light-grey,
  "dark": $dark,
);
$body-color: $dark;
$dropdown-link-color: $dark;
$dropdown-link-hover-color: $light;
$dropdown-link-hover-bg: $primary;
$navbar-dark-hover-color: rgba($light, .9);
$grid-gutter-width: 32px;
$input-border-color: $dark;
$small-font-size: 12px;
$font-size-base: .8125rem;
$lead-font-size: 16px;
$h5-font-size: 16px;
$h4-font-size: 18px;
$h3-font-size: 24px;
$h2-font-size: 24px;
$h1-font-size: $font-size-base * 2.5;
$headings-line-height: 1.25;
$btn-border-radius: 5px;
$btn-border-radius-lg: 5px;
$btn-border-radius-sm: 5px;
$card-border-radius: 16px;
$input-border-radius: 0px;
$font-family-sans-serif: avenir;
$font-family-monospace: avenir;
$enable-responsive-font-sizes: true;

$spacer: 1rem !default;
$spacers: (
  ) !default;
$spacers: map-merge((0: 0,
      8: ($spacer * .5,
      ),
      16: ($spacer,
      ),
      24: ($spacer * 1.5,
      ),
      32: ($spacer * 2,
      ),
      40: ($spacer * 2.5,
      ),
      48: ($spacer * 3,
      ),
      56: ($spacer * 3.5,
      ),
      64: ($spacer * 4,
      ),
      72: ($spacer * 4.5,
      ),
      80: ($spacer * 5,
      ),
      88: ($spacer * 5.5,
      ),
      96: ($spacer * 6,
      ),
      104: ($spacer * 6.5,
      ),
      112: ($spacer * 7,
      ),
      120: ($spacer * 7.5,
      ),
      128: ($spacer * 8,
      ),
      136: ($spacer * 8.5,
      ),
      144: ($spacer * 9,
      ),
      152: ($spacer * 9.5,
      ),
      168: ($spacer * 10.5,
      ),
    ),
    $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  ) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px) !default;